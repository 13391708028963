<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" />

    <v-row class="my-4">
      <v-col>
        <h1>CHECKLIST</h1>
      </v-col>
      <v-col md="8">
        <div class="text-right">
          <v-btn
            color="primary"
            class="mr-2"
            @click="$router.push({ name: 'Foreman' })"
            >Main
          </v-btn>
          <v-btn color="primary" @click="$router.push({ name: 'Prep' })"
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <customer-info customer="true" />

    <v-row>
      <v-col>
        <span>
          F+3/12<br />
          10/26/2020 11/17/2020<br />
          Day 1 of 12
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="8">
        <h3>PROJECT CHECKLIST</h3>
      </v-col>
      <v-col md="4" class="text-right">
        <v-btn color="primary" dark class="mr-2" @click="dialog = true">
          Add
        </v-btn>
        <v-btn color="primary">Spanish </v-btn>
      </v-col>
    </v-row>

    <datatable :headers="headers" :staticData="items"></datatable>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click.native="dialog = false">
              <v-img src="../../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>Add Material</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="pr-10 pl-10">
                      <v-col cols="12" sm="12">
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-text-field
                              label="Type"
                              required
                              v-model="checklistItems.type"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-text-field
                              label="Item"
                              required
                              v-model="checklistItems.item"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <div class="text-right">
                              <v-btn color="primary" dark @click="saveFormData">
                                Add
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";
import CustomerInfo from "./CustomerInfo";

export default {
  name: "PrepChecklist",
  components: { CustomerInfo, Datatable },
  data() {
    return {
      componentURL: "",
      dialog: false,
      valid: false,
      headers: [
        { text: "Type", value: "type" },
        { text: "Item", value: "item" },
      ],
      items: [],
      checklistItems: {
        type: "",
        item: "",
      },
    };
  },
  methods: {
    saveFormData() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.items.push(this.checklistItems);
        this.dialog = false;
        this.checklistItems = { type: "", item: "" };
        this.reset = true;
      }
    },
  },
};
</script>

<style scoped></style>
